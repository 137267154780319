<template>
  <validation-observer
    ref="signupForm"
    #default="{invalid}"
  >
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="register"
      >
        <!-- First Name -->
        <b-form-group
          label="First Name"
          label-for="first_name"
        >
          <validation-provider
            #default="{ errors }"
            name="First Name"
            vid="first_name"
            rules="required"
          >
            <b-form-input
              id="first_name"
              v-model="user.first_name"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="first_name"
              data-vv-validate-on="blur"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Last Name -->
        <b-form-group
          label="Last Name"
          label-for="last_name"
        >
          <validation-provider
            #default="{ errors }"
            name="Last Name"
            vid="last_name"
            rules="required"
          >
            <b-form-input
              id="last_name"
              v-model="user.last_name"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="last_name"
              data-vv-validate-on="blur"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- email -->
        <b-form-group
          :label="$t(`login.email_placeholder`)"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t(`login.email_placeholder`)"
            vid="email"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="user.email"
              :state="errors.length > 0 ? false:null"
              :class="errors.length > 0 ? 'is-invalid':null"
              name="email"
              data-vv-validate-on="blur"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">{{ $t(`login.password_placeholder`) }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t(`login.password_placeholder`)"
            vid="password"
            :rules="{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required }"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="login-password"
                v-model="user.password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            <b-alert
              :show="errors.length > 0"
              variant="warning"
              class="mt-1"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span>{{ $t('password_warning') }}</span>
              </div>
            </b-alert>
          </validation-provider>
        </b-form-group>

        <!-- confirm password -->
        <b-form-group
          label-for="confirm-password"
          label="Confirm Password"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t(`input_fields.confirm_password`)"
            vid="password-confirm"
            :rules="{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required, confirmed: 'password' }"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="confirm-password"
                v-model="user.password_confirm"
                :type="passwordFieldTypeRetype"
                name="password-confirm"
                :state="errors.length > 0 ? false:null"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            <b-alert
              :show="errors.length > 0"
              variant="warning"
              class="mt-1"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span>{{ $t('password_warning') }}</span>
              </div>
            </b-alert>
          </validation-provider>
        </b-form-group>

        <!-- Role Select -->
        <b-form-group
          label="Select Role"
          label-for="role-select"
        >
          <validation-provider
            #default="{ errors }"
            name="Role"
            vid="role-select"
            rules="required"
          >
            <v-select
              id="role-select"
              v-model="user.role"
              name="role-select"
              :reduce="role => role.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :class="errors.length > 0 ? 'is-invalid':null"
              :state="errors.length > 0 ? false:null"
              :options="roles"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Terms Checkbox -->
        <validation-provider
          #default="{ errors }"
          name="Terms"
          vid="terms-checkbox"
          rules="required"
        >
          <b-form-checkbox
            id="terms-checkbox"
            v-model="termsSelected"
            name="terms-checkbox"
            :unchecked-value="null"
          >
            <span>I agree and consent to DenTyme's <a
              :href="termsAndConditions"
              target="_blank"
            >Terms and Conditions</a> and <a
              :href="privacyPolicy"
              target="_blank"
            >Privacy Policy</a></span>
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <!-- server alert -->
        <b-alert
          v-height-fade.appear
          class="mt-1"
          variant="danger"
          :show="serverError !== null"
        >
          <div
            class="alert-body"
          >
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            <span v-html="serverError" />
          </div>
        </b-alert>

        <!-- submit buttons -->
        <b-button
          class="mt-1"
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
        >
          <span>{{ $t('buttons.submit') }}</span>
        </b-button>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BAlert,
  // BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import {
  heightFade,
} from '@core/directives/animations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  regex,
  email,
} from '@validations'
import {
  togglePasswordVisibility,
} from '@core/mixins/ui/forms'
// import {
//   getHomeRouteForLoggedInUser,
// } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { initialAbility } from '@/libs/acl/config'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'

export default {
  components: {
    BAlert,
    // BLink,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      user: {
        email: null,
        password: null,
      },
      required,
      email,
      regex,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      termsSelected: null,
      roles: [
        {
          label: 'Dentist (Owner)',
          value: 'dentist',
        },
        {
          label: 'Office Manager',
          value: 'office_manager',
        },
      ],
      passwordFieldTypeRetype: 'password',
      privacyPolicy: process.env.VUE_APP_PRIVACY_POLICY,
      termsAndConditions: process.env.VUE_APP_TERMS_AND_CONDITIONS,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('role', ['getRoles']),
    ...mapActions('auth', ['registerUser']),
    register() {
      this.$refs.signupForm.validate().then(async success => {
        if (success) {
          this.serverError = null
          this.loading = true
          try {
            const { password_confirm, ...truncated } = this.user
            const payload = {
              ...truncated,
              token: this.$route.query.token,
            }
            await this.registerUser(payload)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Your DenTyme Account was successfully created. Please login with your new account',
              },
            }, 5000)
            this.$router.push({ name: 'auth-login', replace: true })
            /*
            const newUser = registerResponse.data
            await this.getUserProfile()
            console.log(newUser.ability)
            console.log(newUser)
            console.log(this.activeUser)
            this.$ability.update(newUser.ability || initialAbility)
            this.$router.replace(getHomeRouteForLoggedInUser(newUser.role.name, true)).then(() => {})
              .catch(error => {
                console.debug(`Redirect error after signup: ${error.message}`)
                // this.serverError = this.$t('errors.generic')
              })
              .finally(
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: 'Your DenTyme Account was successfully created.',
                  },
                }),
              )
              */
          } catch (error) {
            console.error(`Registration Error: ${error.message}`)
            if (error.response) {
              if (error.response.status < 500) {
                this.serverError = error.response.data.message
              } else {
                this.serverError = this.$t('errors.generic')
              }
            } else {
              this.serverError = this.$t('errors.generic')
            }
          } finally {
            this.loading = false
          }
        }
      })
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
