var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"signupForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-overlay',{attrs:{"show":_vm.loading,"variant":_vm.overlayVariant,"opacity":_vm.overlayOpacity,"rounded":"sm"}},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"name":"first_name","data-vv-validate-on":"blur"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"name":"last_name","data-vv-validate-on":"blur"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t("login.email_placeholder"),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":_vm.$t("login.email_placeholder"),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","data-vv-validate-on":"blur"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.$t("login.password_placeholder")))])]),_c('validation-provider',{attrs:{"name":_vm.$t("login.password_placeholder"),"vid":"password","rules":{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-alert',{staticClass:"mt-1",attrs:{"show":errors.length > 0,"variant":"warning"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('password_warning')))])],1)])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"confirm-password","label":"Confirm Password"}},[_c('validation-provider',{attrs:{"name":_vm.$t("input_fields.confirm_password"),"vid":"password-confirm","rules":{ regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, required: _vm.required, confirmed: 'password' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"confirm-password","type":_vm.passwordFieldTypeRetype,"name":"password-confirm","state":errors.length > 0 ? false:null},model:{value:(_vm.user.password_confirm),callback:function ($$v) {_vm.$set(_vm.user, "password_confirm", $$v)},expression:"user.password_confirm"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-alert',{staticClass:"mt-1",attrs:{"show":errors.length > 0,"variant":"warning"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('password_warning')))])],1)])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Select Role","label-for":"role-select"}},[_c('validation-provider',{attrs:{"name":"Role","vid":"role-select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"role-select","name":"role-select","reduce":function (role) { return role.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"options":_vm.roles},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"Terms","vid":"terms-checkbox","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"terms-checkbox","name":"terms-checkbox","unchecked-value":null},model:{value:(_vm.termsSelected),callback:function ($$v) {_vm.termsSelected=$$v},expression:"termsSelected"}},[_c('span',[_vm._v("I agree and consent to DenTyme's "),_c('a',{attrs:{"href":_vm.termsAndConditions,"target":"_blank"}},[_vm._v("Terms and Conditions")]),_vm._v(" and "),_c('a',{attrs:{"href":_vm.privacyPolicy,"target":"_blank"}},[_vm._v("Privacy Policy")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],staticClass:"mt-1",attrs:{"variant":"danger","show":_vm.serverError !== null}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.serverError)}})],1)]),_c('b-button',{staticClass:"mt-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loading}},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.submit')))])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }