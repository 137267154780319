<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- DenTyme logo-->
      <dentyme-logo />
      <!-- /DenTyme logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <dentyme-info-slider />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Signup -->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            v-if="!invalidSignupURL"
            class="mt-1 font-weight-bold"
            title-tag="h2"
            v-html="$t(`welcome`)"
          />
          <b-card-text
            v-if="!invalidSignupURL"
            class="mt-5"
          >
            {{ $t(`signup.please_fill`) }}
          </b-card-text>

          <!-- form -->
          <signup-form v-if="!invalidSignupURL" />
          <!-- /form -->

          <div v-if="invalidSignupURL">
            <sweet-alert-static display-type="danger" />
            <div class="vx-card__title mb-4 text-center">
              <h1 class="mb-4">
                Sorry, this link is no longer valid
              </h1>
              <p class="m-4">
                Please contact <a href="mailto:support@dentyme.io">support@dentyme.io</a> to request a new link. Click <b-link :to="{path:'/', replace: true}"> here
                </b-link> to return to the home page.
              </p>
            </div>
          </div>
        </b-col>
      </b-col>
    <!-- /Signup-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import DentymeLogo from '@/views/components/dentyme-logo/DentymeLogo.vue'
import {
  BRow, BCol, BCardText, BCardTitle, VBTooltip, BLink,
} from 'bootstrap-vue'
import store from '@/store/index'
import SignupForm from '@/views/components/forms/SignupForm.vue'
import DentymeInfoSlider from '@/views/components/dentyme-info-slider/DentymeInfoSlider.vue'
import SweetAlertStatic from '@/views/components/sweet-alert-static/SweetAlertStatic.vue'
import { mapActions } from 'vuex'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    DentymeInfoSlider,
    DentymeLogo,
    SignupForm,
    SweetAlertStatic,
    BLink,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      invalidSignupURL: true,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  async created() {
    try {
      this.invalidSignupURL = true
      if (this.$route.query.token) {
        const payload = {
          token: this.$route.query.token,
        }
        await this.verifySignupToken(payload)
        this.invalidSignupURL = false
      }
    } catch (e) {
      this.invalidSignupURL = true
    }
  },
  methods: {
    ...mapActions('auth', ['verifySignupToken']),
  },
  metaInfo() {
    return { title: this.$t('page_titles.signup'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@/assets/scss/variables/_variables.scss';
.brand-text {
  color: $primary;
}
@media (max-width: 768px) {
  .brand-logo {
    display: none;
  }
}
</style>
